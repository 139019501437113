import ArrowDown18 from '@sats-group/icons/18/arrow-down';
import cn from 'classnames';
import Collapse from 'react-tiny-collapse';
import React, { useEffect, useRef } from 'react';

import Text from '../text/text';
import useClickOutside from '../../hooks/use-click-outside';
import useEscape from '../../hooks/use-escape';
import useToggle from '../../hooks/use-toggle';

import type { MenuDropdown as Props } from './header.types';

const MenuDropdown: React.FunctionComponent<Props> = ({
  id,
  isSelected,
  items,
  onToggle,
  title,
}) => {
  const [dropdownOpen, dropdownToggle, , close] = useToggle(false);
  const dropdown = useRef(null);
  useEffect(() => {
    if (onToggle) {
      onToggle(dropdownOpen);
    }
  }, [dropdownOpen]);

  useClickOutside(dropdown, close);
  useEscape(close);

  return (
    <div className="header__menu-dropdown-wrapper" ref={dropdown}>
      <button
        aria-controls={id}
        aria-expanded={dropdownOpen}
        className={cn('header__menu-dropdown-trigger', {
          'header__menu-dropdown-trigger--active': dropdownOpen || isSelected,
        })}
        onClick={dropdownToggle}
      >
        <Text elementName="span" theme={Text.themes.medium}>
          {title}
        </Text>
        <div
          className={cn('header__menu-icon', {
            'header__menu-icon--flipped': dropdownOpen,
          })}
        >
          <ArrowDown18 />
        </div>
      </button>
      <div
        className={cn('header__menu-dropdown', {
          'header__menu-dropdown--open': dropdownOpen,
        })}
      >
        <Collapse componentProps={{ id }} isOpen={dropdownOpen}>
          <ul
            className={cn('header__menu-dropdown-groups', {
              'header__menu-dropdown-groups--wide': items.length > 3,
            })}
          >
            {items.map(({ key, links, title }) => (
              <li className="header__menu-dropdown-group" key={key}>
                {title ? (
                  <div className="header__menu-dropdown-group-title">
                    <Text
                      size={Text.sizes.basic}
                      theme={Text.themes.emphasis}
                      variant={Text.variants.hero}
                    >
                      {title}
                    </Text>
                  </div>
                ) : null}
                <div className="header__menu-dropdown-group-links">
                  {links.map(link => (
                    <div key={link.text}>
                      <a className="header__menu-dropdown-group-link" {...link}>
                        <Text elementName="span" size={Text.sizes.basic}>
                          {link.text}
                        </Text>
                      </a>
                    </div>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </Collapse>
      </div>
    </div>
  );
};

export default MenuDropdown;
