import React from 'react';

import CleanHeader from '../clean-header/clean-header';

import type { CleanLayout as Props } from './clean-layout.types';

const CleanLayout: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  header,
}) => (
  <div className="clean-layout">
    <div className="clean-layout__header">
      <CleanHeader {...header} />
    </div>
    <main className="clean-layout__children">{children}</main>
  </div>
);

export default CleanLayout;
