import React from 'react';

import ArticleList from 'root/client/components/article-list/article-list';
import ContentContainer from 'root/client/components/content-container/content-container';
import Hero from 'root/client/components/hero/hero';
import Layout from 'root/client/components/layout/layout';
import LinkCards from 'root/client/components/link-cards/link-cards';
import ProductOptionCards from 'root/client/components/product-option-cards/product-option-cards';
import SplitInfoModule from 'root/client/components/split-info-module/split-info-module';

import type { RootPage as Props } from './root-page.types';

const RootPage: React.FunctionComponent<Props> = ({
  articles,
  hero,
  highlight,
  layout,
  imageLinksSection,
  products,
  productsId,
}) => (
  <Layout {...layout}>
    {hero ? <Hero {...hero} /> : null}
    <div className="root-page__section">
      <ContentContainer>
        <LinkCards {...imageLinksSection} />
      </ContentContainer>
    </div>
    {products ? (
      <div className="root-page__section" id={productsId}>
        <ContentContainer>
          <ProductOptionCards {...products} />
        </ContentContainer>
      </div>
    ) : null}
    {articles || highlight ? (
      <div className="root-page__section">
        {highlight ? (
          <ContentContainer>
            <SplitInfoModule {...highlight} featured />
          </ContentContainer>
        ) : null}
        {articles ? (
          <ContentContainer>
            <ArticleList {...articles} />
          </ContentContainer>
        ) : null}
      </div>
    ) : null}
  </Layout>
);

export default RootPage;
