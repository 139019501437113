import cn from 'classnames';
import Loading24 from '@sats-group/icons/24/loading';
import React from 'react';
import type { ValueOf } from 'type-fest';

import Text from 'root/client/components/text/text';

import {
  type VisuallyButton as Props,
  sizes,
  themes,
} from './visually-button.types';

const textSizes: Record<ValueOf<typeof sizes>, ValueOf<typeof Text.sizes>> = {
  basic: 'basic',
  large: 'large',
  small: 'small',
};

const textThemes: Record<
  ValueOf<typeof themes>,
  ValueOf<typeof Text.themes>
> = {
  'cta-clean': 'normal',
  'cta-secondary': 'normal',
  'cta-secondary-clean': 'normal',
  'cta-secondary-white': 'normal',
  'primary-white': 'medium',
  'secondary-white': 'medium',
  complete: 'medium',
  cta: 'normal',
  primary: 'medium',
  secondary: 'medium',
  tertiary: 'medium',
};

const textVariants: Record<
  ValueOf<typeof themes>,
  ValueOf<typeof Text.variants>
> = {
  'cta-clean': 'hero',
  'cta-secondary': 'hero',
  'cta-secondary-clean': 'hero',
  'cta-secondary-white': 'hero',
  'primary-white': 'content',
  'secondary-white': 'content',
  complete: 'content',
  cta: 'hero',
  primary: 'content',
  secondary: 'content',
  tertiary: 'content',
};

const VisuallyButton: React.FunctionComponent<Props> & {
  sizes: typeof sizes;
  themes: typeof themes;
} = ({
  elementName = 'button',
  icon,
  iconOnly,
  iconPosition,
  loading,
  role = 'button',
  size = sizes.basic,
  testId,
  text,
  theme = themes.primary,
  wide,
  ...rest
}) =>
  React.createElement(
    elementName,
    {
      'aria-label': icon && iconOnly ? text : undefined,
      className: cn('visually-button', {
        'visually-button--icon-only': icon && iconOnly,
        'visually-button--loading': loading,
        [`visually-button--size-${size}`]: size,
        [`visually-button--theme-${theme}`]: theme,
        'visually-button--wide': wide,
      }),
      'data-testid': testId,
      role,
      ...rest,
    },
    <React.Fragment>
      <div className="visually-button__curtain">
        <div className="visually-button__spinner">
          <Loading24 />
        </div>
      </div>
      <div className="visually-button__main">
        {icon && iconPosition !== 'right' ? (
          <div className="visually-button__icon">{icon}</div>
        ) : null}
        {icon && iconOnly ? null : (
          <div className="visually-button__text">
            <Text
              elementName="div"
              size={textSizes[size]}
              theme={textThemes[theme]}
              variant={textVariants[theme]}
            >
              {text}
            </Text>
          </div>
        )}
        {icon && iconPosition === 'right' ? (
          <div className="visually-button__icon">{icon}</div>
        ) : null}
      </div>
    </React.Fragment>,
  );

VisuallyButton.sizes = sizes;
VisuallyButton.themes = themes;

export default VisuallyButton;
