import cn from 'classnames';
import React from 'react';

import ContentContainer from '../content-container/content-container';
import Image from '../image/image';
import LinkButton from '../link-button/link-button';
import Text from '../text/text';

import type { Hero as Props } from './hero.types';

const Hero: React.FunctionComponent<Props> = ({
  actions,
  blurb,
  disclaimer,
  image,
  short,
  texts,
  title,
}) => (
  <div className={cn('hero', { 'hero--short': short })}>
    <div className="hero__image hero__image--static">
      <Image {...image} />
    </div>
    <div className="hero__image hero__image--fill">
      <Image {...image} aspectRatio={Image.aspectRatios.Fill} />
    </div>
    <div className="hero__content">
      <ContentContainer>
        {blurb ? (
          <div className="hero__blurb">
            <Text
              size={Text.sizes.header1}
              tight
              variant={Text.variants.contrast}
            >
              {blurb}
            </Text>
          </div>
        ) : null}
        <div className="hero__title">
          <Text
            elementName="h1"
            size={Text.sizes.header1}
            tight
            variant={Text.variants.hero}
          >
            {title}
          </Text>
        </div>
        {texts.length ? (
          <div className="hero__texts">
            {texts.map(line => (
              <Text key={line}>{line}</Text>
            ))}
          </div>
        ) : null}
        {actions.length ? (
          <React.Fragment>
            <div className="hero__actions hero__actions--vertical">
              {actions.map((action, index) => (
                <LinkButton
                  {...action}
                  key={action.href}
                  theme={
                    index
                      ? LinkButton.themes.ctaSecondary
                      : LinkButton.themes.cta
                  }
                  wide
                />
              ))}
            </div>
            <div className="hero__actions hero__actions--horizontal">
              {actions.map((action, index) => (
                <LinkButton
                  {...action}
                  key={action.href}
                  theme={
                    index
                      ? LinkButton.themes.ctaSecondaryWhite
                      : LinkButton.themes.cta
                  }
                />
              ))}
            </div>
          </React.Fragment>
        ) : null}
        {disclaimer ? (
          <div className="hero__disclaimer">
            <Text size={Text.sizes.interface}>{disclaimer}</Text>
          </div>
        ) : null}
      </ContentContainer>
    </div>
  </div>
);

export default Hero;
