export enum Theme {
  Red = 'red',
  White = 'white',
  Beige = 'beige',
}

export type Logo = {
  href?: string;
  label: string;
  theme?: Theme;
};
