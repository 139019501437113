export const fillRegistrationForm = () => {
  const id = window.crypto.randomUUID();

  const pad = (int: number) => {
    if (int > 9) {
      return int.toString();
    }

    return int.toString().padStart(2, '0');
  };

  const day = Math.floor(Math.random() * 28) + 1;
  const month = Math.floor(Math.random() * 12) + 1;
  const year = new Date().getFullYear() - Math.floor(Math.random() * 40) - 18;

  const formData = [
    {
      name: 'firstName',
      fieldName: 'value',
      value: `xxx-first-${id}`,
    },
    {
      name: 'lastName',
      fieldName: 'value',
      value: `xxx-last-${id}`,
    },
    {
      name: 'email',
      fieldName: 'value',
      value: `xxx-test-${id}@example.com`,
    },
    {
      name: 'birthDay',
      fieldName: 'value',
      value: `${Math.floor(Math.random() * 28) + 1}`,
    },
    {
      name: 'birthMonth',
      fieldName: 'value',
      value: `${Math.floor(Math.random() * 12) + 1}`,
    },
    {
      name: 'birthYear',
      fieldName: 'value',
      value: `${
        new Date().getFullYear() - Math.floor(Math.random() * 40) - 18
      }`,
    },
    {
      name: 'birthDate',
      fieldName: 'value',
      value: `${year}-${pad(month)}-${pad(day)}`,
    },
    {
      name: 'gender',
      fieldName: 'checked',
      value: true,
    },
    {
      name: 'address',
      fieldName: 'value',
      value: `xxx-${id}`,
    },
    {
      name: 'postalCode',
      fieldName: 'value',
      value: `0558`,
    },
    {
      name: 'termsAndConditions',
      fieldName: 'checked',
      value: true,
    },
  ];

  formData.forEach(({ name, fieldName, value }) => {
    const element = document.querySelector(`[name=${name}]`);
    if (element) {
      // @ts-expect-error
      element[fieldName] = value;
    }
    return;
  });

  // NOTE: This nonsense is to support both kinds of phone number inputs
  const phoneNumberInput = document.querySelector('[name="phoneNumber"]');
  if (!phoneNumberInput) {
    return;
  }

  const phoneNumber = String(
    Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000,
  );

  phoneNumberInput.setAttribute('value', `+47${phoneNumber}`);

  const telInput = document.querySelector('[type="tel"]');
  if (telInput) {
    telInput.setAttribute('value', phoneNumber);
  }
};
