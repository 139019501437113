import React from 'react';

import ColorBlockLayout from 'root/client/components/color-block-layout/color-block-layout';
import Image from 'root/client/components/image/image';
import Layout from 'root/client/components/layout/layout';
import Link from 'root/client/components/link/link';
import LinkButton from 'root/client/components/link-button/link-button';
import ScaleBar from 'root/client/components/scale-bar/scale-bar';
import Text from 'root/client/components/text/text';
import TextModule from 'root/client/components/text-module/text-module';

import type { GroupExerciseTypePage as Props } from './group-exercise-type-page.types';

const GroupExerciseTypePage: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ charts, content, book, image, layout, previousPage }) => (
  <Layout {...layout}>
    <div className="group-exercise-type-page">
      <ColorBlockLayout>
        <ColorBlockLayout.Block
          color={ColorBlockLayout.colors.Secondary}
          padding={ColorBlockLayout.paddings.AllSides}
        >
          <div className="group-exercise-type-page__primary">
            <div className="group-exercise-type-page__previous-page">
              {previousPage ? <Link {...previousPage} /> : null}
            </div>
            <TextModule {...content} />
            <div className="group-exercise-type-page__book">
              <LinkButton {...book} />
            </div>
            {charts ? (
              <ul className="group-exercise-type-page__charts">
                {charts.map(({ label, chart }) => (
                  <li>
                    <div className="group-exercise-type-page__chart-title">
                      <Text size={Text.sizes.small}>{label}</Text>
                    </div>
                    <ScaleBar {...chart} />
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </ColorBlockLayout.Block>
        <ColorBlockLayout.Block
          color={ColorBlockLayout.colors.Secondary}
          padding={ColorBlockLayout.paddings.None}
        >
          <div className="group-exercise-type-page__secondary">
            <div className="group-exercise-type-page__image">
              <Image {...image} aspectRatio={Image.aspectRatios.Fill} />
            </div>
          </div>
        </ColorBlockLayout.Block>
      </ColorBlockLayout>
    </div>
  </Layout>
);

export default GroupExerciseTypePage;
