import React, { useEffect, useState } from 'react';
import Rtc from 'react-tiny-collapse';

const Collapse: React.FC<Parameters<typeof Rtc>[0]> = props => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  useEffect(() => {
    setPrefersReducedMotion(
      window.matchMedia('(prefers-reduced-motion: reduce)').matches,
    );
  }, []);

  return (
    <Rtc {...props} duration={prefersReducedMotion ? 0 : props.duration} />
  );
};

export default Collapse;
