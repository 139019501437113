import cn from 'classnames';
import React from 'react';

import type { ContentBlock as Props } from './content-block.types';

const ContentBlock: React.FC<React.PropsWithChildren<Props>> = ({
  center,
  children,
  id,
  type,
}) => (
  <div
    className={cn('content-blocks__block', {
      'content-blocks__block--center': center,
    })}
    data-block-id={id}
    data-block-type={type}
  >
    {children}
  </div>
);

export default ContentBlock;
