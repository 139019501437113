import cn from 'classnames';
import { BLOCKS } from '@contentful/rich-text-types';
import type {
  NodeRenderer,
  Options,
} from '@contentful/rich-text-react-renderer';
import React from 'react';

import Image from '../image/image';
import Text from '../text/text';
import type { Text as TextProps } from '../text/text.types';

import EmbeddedEntryRenderer from './embedded-entry-renderer';
import { type ChildClassNames, Theme } from './rich-text.types';
import TableRenderer from './table-renderer';

/* eslint-disable react/no-multi-comp */

const text =
  (elementName: string, props: TextProps): NodeRenderer =>
  (_, children) => (
    <Text elementName={elementName} {...props}>
      {children}
    </Text>
  );

const NodeWrapper: React.FC<
  React.PropsWithChildren<{
    nodeType: string;
  }>
> = ({ nodeType, children }) => (
  <div className={`rich-text__node-${nodeType}`} data-node-type={nodeType}>
    {children}
  </div>
);

export default (classNames: ChildClassNames = {}, theme?: Theme): Options => ({
  renderNode: {
    [BLOCKS.HEADING_2]: text('h2', { size: Text.sizes.header3 }),
    [BLOCKS.HEADING_3]: text('h3', { size: Text.sizes.large }),
    [BLOCKS.HEADING_4]: text('h4', { size: Text.sizes.header3 }),
    [BLOCKS.PARAGRAPH]: text(
      'p',
      theme === Theme.Intro
        ? { size: Text.sizes.large }
        : { size: Text.sizes.basic },
    ),
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const fields = node?.data?.target?.fields || {};
      const src = fields.file?.url;

      return src ? (
        <Image
          className={cn('rich-text__image', classNames.image)}
          alt={fields.description || fields.title || ''}
          src={src}
        />
      ) : null;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => (
      <NodeWrapper nodeType={node.nodeType}>
        {EmbeddedEntryRenderer(node, children)}
      </NodeWrapper>
    ),
    [BLOCKS.TABLE]: (node, children) => (
      <div className="rich-text__table" data-node-type={node.nodeType}>
        {TableRenderer(node, children)}
      </div>
    ),
  },
});
