import React from 'react';

import Text from 'root/client/components/text/text';

import type { CheckoutHeader as Props } from './checkout-header.types';

const CheckoutHeader: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ title }) => (
  <div className="checkout-header">
    <Text
      elementName="h1"
      size={Text.sizes.header2}
      variant={Text.variants.hero}
    >
      {title}
    </Text>
  </div>
);

export default CheckoutHeader;
