import { useState } from 'react';

export default (initialState = false) => {
  const [state, setState] = useState(initialState);

  const toggle = () => setState(prevState => !prevState);
  const turnOn = () => setState(true);
  const turnOff = () => setState(false);

  return [state, toggle, turnOn, turnOff] as [
    state: typeof state,
    toggle: typeof toggle,
    turnOn: typeof turnOn,
    turnOff: typeof turnOff,
  ];
};
