import type { NodeRenderer } from '@contentful/rich-text-react-renderer';
import React from 'react';

import CTABanner from '../cta-banner/cta-banner';
import EmbedYoutube from '../embed-youtube/embed-youtube';
import Table from '../table/table';

import {
  toCtaBannerProps,
  toEmbedYoutubeProps,
  toTableProps,
} from './to-component-props';

const EmbeddedEntryRenderer: NodeRenderer = node => {
  const type = node?.data?.target?.sys?.contentType?.sys?.id;
  switch (type) {
    case 'ctaBanner': {
      const props = toCtaBannerProps(node);
      return props ? (
        <div data-node-type={node.nodeType} className="rich-text__cta-banner">
          <CTABanner {...props} />
        </div>
      ) : null;
    }
    case 'table': {
      const props = toTableProps(node);
      return props ? (
        <div data-node-type={node.nodeType} className="rich-text__table">
          <Table {...props} />
        </div>
      ) : null;
    }
    case 'youtubeEmbed': {
      const props = toEmbedYoutubeProps(node);
      return props ? (
        <div data-node-type={node.nodeType}>
          <EmbedYoutube {...props} />
        </div>
      ) : null;
    }
    default: {
      return null;
    }
  }
};

export default EmbeddedEntryRenderer;
