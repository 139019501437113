import React from 'react';

const VisuallyHidden: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) =>
  React.Children.count(children) === 0 ? null : (
    <span className="visually-hidden">{children}</span>
  );

export default VisuallyHidden;
