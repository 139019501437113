import cn from 'classnames';
import Confirm from '@sats-group/icons/18/confirm';
import Error from '@sats-group/icons/18/error';
import Info from '@sats-group/icons/18/info';
import React from 'react';
import Warning from '@sats-group/icons/18/warning-sign';

import Link from '../link/link';
import Text from '../text/text';

import type { Banner as Props } from './banner.types';
import { themes } from './banner.types';

const iconDictionary = {
  [themes.error]: Error(),
  [themes.information]: Info(),
  [themes.neutral]: Info(),
  [themes.success]: Confirm(),
  [themes.warning]: Warning(),
};

const Banner: React.FunctionComponent<Props> & {
  themes: typeof themes;
} = ({ action, text, theme = Banner.themes.neutral, title }) => {
  const BannerIcon = iconDictionary[theme];

  return (
    <div className={cn('banner', { [`banner--${theme}`]: theme })}>
      <div
        className={cn('banner__icon', { [`banner__icon--${theme}`]: theme })}
      >
        {BannerIcon}
      </div>
      <div className="banner__content">
        {title ? <Text theme={Text.themes.emphasis}>{title}</Text> : null}
        <Text>{text}</Text>
        {action ? <Link {...action} /> : null}
      </div>
    </div>
  );
};

Banner.themes = themes;

export default Banner;
