import Check16 from '@sats-group/icons/16/check';
import cn from 'classnames';
import React from 'react';

import type { Checkbox as Props, RestProps } from './checkbox.types';

const Checkbox: React.FunctionComponent<RestProps & Props> = ({
  className,
  isRequired,
  label,
  name,
  value,
  ...rest
}) => (
  <label className={cn('checkbox', className)}>
    <input
      aria-label={label}
      className="checkbox__input"
      name={name}
      required={isRequired}
      type="checkbox"
      value={value}
      {...rest}
    />
    <div className="checkbox__label">
      <div className="checkbox__fake-element">
        <Check16 />
      </div>
      <div className="checkbox__text">
        {label}
        {isRequired ? <sup aria-hidden={true}>*</sup> : null}
      </div>
    </div>
  </label>
);

export default Checkbox;
