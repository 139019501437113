import React from 'react';

import Button from '../button/button';
import HiddenInput from '../hidden-input/hidden-input';
import Text from '../text/text';
import TextField from '../text-field/text-field';

import type { DiscountCodeInput as Props } from './discount-code-input.types';

const DiscountCodeInput: React.FC<Props> = ({
  disclaimer,
  endpoint,
  hiddenInputs,
  input,
  invalidCodeHelpText,
  method,
  submitLabel,
  text,
  title,
  validCode,
}) => (
  <form className="discount-code-input" action={endpoint} method={method}>
    {hiddenInputs.map(input => (
      <HiddenInput {...input} key={input.name} />
    ))}
    <div className="discount-code-input__texts">
      {title ? <Text size={Text.sizes.large}>{title}</Text> : null}
      {text ? (
        <Text elementName="p" size={Text.sizes.small}>
          {text}
        </Text>
      ) : null}
    </div>
    <div>
      <TextField
        {...input}
        required
        wide
        valid={validCode}
        help={invalidCodeHelpText}
      />
    </div>
    <div className="discount-code-input__actions">
      <Button
        size={Button.sizes.small}
        text={submitLabel}
        theme={Button.themes.secondary}
        type="submit"
      />
    </div>
    {disclaimer ? <Text size={Text.sizes.small}>{disclaimer}</Text> : null}
  </form>
);

export default DiscountCodeInput;
