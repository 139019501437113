import React from 'react';

import Link from 'root/client/components/link/link';
import Text from 'root/client/components/text/text';

import type { OpeningHoursTable as Props } from './opening-hours-table.types';

const OpeningHoursTable: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ hours, link, title }) => (
  <div className="opening-hours-table">
    <Text elementName="h2" size={Text.sizes.header3}>
      {title}
    </Text>
    {hours ? (
      <dl className="opening-hours-table__list">
        {hours.map(({ rowId, title, text }) => (
          <div className="opening-hours-table__item" key={rowId}>
            <dt>{title}</dt>
            <dd>{text}</dd>
          </div>
        ))}
      </dl>
    ) : null}
    {link ? <Link {...link} /> : null}
  </div>
);

export default OpeningHoursTable;
