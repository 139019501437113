export const appendScript = (
  src: string,
  onLoad: () => void,
  options: Partial<{
    crossorigin: string;
    integrity: string;
    onError: () => void;
  }> = {},
): void => {
  if (document.querySelector(`[src="${src}"]`)) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('src', src);
  script.onload = onLoad;
  if (options.onError) {
    script.onerror = options.onError;
  }
  if (options.crossorigin) {
    script.setAttribute('crossorigin', options.crossorigin);
  }
  if (options.integrity) {
    script.setAttribute('integrity', options.integrity);
  }
  document.body.appendChild(script);
};
