import React from 'react';

import VisuallyHidden from '../visually-hidden/visually-hidden';

import type { ScaleBar as Props } from './scale-bar.types';

const ScaleBar: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  max = 5,
  value,
}) => (
  <>
    <div className="scale-bar">
      {Array(max)
        .fill(0)
        .map((_, i) => (
          <div
            className={`scale-bar__item scale-bar__item--${
              i < value ? 'filled' : 'empty'
            }`}
            key={i}
          />
        ))}
    </div>
    <VisuallyHidden>
      <meter max={max} min={0} value={value}>
        {value}/{max}
      </meter>
    </VisuallyHidden>
  </>
);

export default ScaleBar;
