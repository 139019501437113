import ArrowDown from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import Collapse from 'react-tiny-collapse';
import React, { useMemo } from 'react';

import useToggle from '../../hooks/use-toggle';
import Text from '../text/text';

import { type Expander as Props } from './expander.types';

const getPosition = (index: number, count: number) => {
  if (count === 1) {
    return 'individual';
  }

  if (index === 0) {
    return 'top';
  }

  if (index === count - 1) {
    return 'bottom';
  }

  return 'middle';
};

const ExpanderListItem: React.FC<
  React.PropsWithChildren<Props<unknown>['items'][number]['listItemProps']> & {
    count: number;
    index: number;
    standalone?: boolean;
  }
> = ({ children, count, faq, id, index, standalone, title }) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const position = getPosition(index, count);

  return (
    <div
      className={`expander__list-item expander__list-item--${position}`}
      itemProp={faq ? 'mainEntity' : undefined}
      itemScope={faq ? faq : undefined}
      itemType={faq ? 'https://schema.org/Question' : undefined}
      key={id}
    >
      <button
        aria-controls={id}
        aria-expanded={isOpen}
        className={cn(
          'expander__trigger',
          { [`expander__trigger--${position}`]: !standalone },
          {
            'expander__trigger--active': isOpen,
          },
        )}
        onClick={toggleOpen}
      >
        <div className="expander__trigger-title">
          <Text elementName="h3" tight>
            {title}
          </Text>
        </div>
        <div
          className={cn('expander__trigger-flourish', {
            'expander__trigger-flourish--flipped': isOpen,
          })}
        >
          <ArrowDown />
        </div>
      </button>
      <Collapse componentProps={{ id }} isOpen={isOpen}>
        <div
          className={cn(`expander__content`, {
            [`expander__content--${position}`]: !standalone,
          })}
          itemProp={faq ? 'acceptedAnswer' : undefined}
          itemScope={faq ? faq : undefined}
          itemType={faq ? 'https://schema.org/Answer' : undefined}
        >
          <div
            className="expander__children"
            itemProp={faq ? 'text' : undefined}
          >
            {children}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

// eslint-disable-next-line react/no-multi-comp
const Expander = <T extends object>({
  faq,
  itemRenderer,
  items,
  standalone,
  title,
}: Props<T> & { itemRenderer: React.FC<T> }) => {
  const count = useMemo(() => items.length, [items]);

  return (
    <div className="expander">
      {title ? (
        <Text
          elementName="h2"
          size={Text.sizes.header2}
          theme={Text.themes.emphasis}
          tight
        >
          {title}
        </Text>
      ) : null}
      <div
        className={cn('expander__list', {
          'expander__list--standalone': standalone,
        })}
      >
        {items.map((item, index) => (
          <ExpanderListItem
            {...item.listItemProps}
            count={count}
            faq={faq}
            index={index}
            key={item.listItemProps.id}
            standalone={standalone}
          >
            {React.createElement(itemRenderer, item.props)}
          </ExpanderListItem>
        ))}
      </div>
    </div>
  );
};

export default Expander;
