import cn from 'classnames';
import React from 'react';

import type { ContentContainer as Props } from './content-container.types';
import { Width } from './content-container.types';

const ContentContainer: React.FunctionComponent<
  React.PropsWithChildren<Props>
> & {
  widths: typeof Width;
} = ({ children, className, width }) => (
  <div
    className={cn(
      'content-container',
      {
        [`content-container--${width}`]: width,
      },
      className,
    )}
  >
    {children}
  </div>
);

ContentContainer.widths = Width;

export default ContentContainer;
