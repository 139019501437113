export const appendStylesheet = (
  href: string,
  options: Partial<{
    crossorigin: string;
    integrity: string;
  }> = {},
): void => {
  if (document.querySelector(`[href="${href}"]`)) {
    return;
  }

  const link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('href', href);
  if (options.crossorigin) {
    link.setAttribute('crossorigin', options.crossorigin);
  }
  if (options.integrity) {
    link.setAttribute('integrity', options.integrity);
  }
  document.body.appendChild(link);
};
