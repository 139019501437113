import React from 'react';

import Breadcrumbs from 'root/client/components/breadcrumbs/breadcrumbs';
import ContentContainer from 'root/client/components/content-container/content-container';
import Layout from 'root/client/components/layout/layout';
import ProfileContactCard from 'root/client/components/profile-contact-card/profile-contact-card';
import PtInfoTextCard from 'root/client/components/pt-info-text-card/pt-info-text-card';
import PtIntroTextCard from 'root/client/components/pt-intro-text-card/pt-intro-text-card';

import type { PersonalTrainerPage as Props } from './personal-trainer-page.types';

const PersonalTrainerPage: React.FunctionComponent<Props> = ({
  breadcrumbs,
  card,
  info,
  intro,
  layout,
}) => (
  <Layout {...layout}>
    <div className="personal-trainer-page">
      <ContentContainer>
        <div className="personal-trainer-page__container">
          <div>
            <Breadcrumbs {...breadcrumbs} />
          </div>
          <div className="personal-trainer-page__content">
            <div className="personal-trainer-page__intro">
              <PtIntroTextCard {...intro} />
            </div>
            <div className="personal-trainer-page__card">
              <ProfileContactCard {...card} />
            </div>
            <div className="personal-trainer-page__info">
              <PtInfoTextCard {...info} />
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  </Layout>
);

export default PersonalTrainerPage;
