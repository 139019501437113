import type React from 'react';
import type { ValueOf } from 'type-fest';

export const colors = {
  dark: 'dark',
  light: 'light',
} as const;

export type LayeredIcon = {
  icons: { icon: React.JSX.Element; color: ValueOf<typeof colors> }[];
};
