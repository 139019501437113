import { useState } from 'react';

export default function useCounter(
  min: number,
  max: number,
  initialValue: number,
) {
  const [value, setValue] = useState(initialValue);

  const next = () => setValue(value => Math.min(value + 1, max));
  const previous = () => setValue(value => Math.max(value - 1, min));
  const set = (value: number) => setValue(Math.min(max, Math.max(min, value)));

  return [value, next, previous, set] as [
    value: typeof value,
    next: typeof next,
    previous: typeof previous,
    set: typeof set,
  ];
}
