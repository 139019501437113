import type { ValueOf } from 'type-fest';

export const themes = {
  featured: 'featured',
} as const;

export type Price = {
  details?: string[];
  originalPrice?: string;
  post: string;
  pre?: string;
  theme?: ValueOf<typeof themes>;
  value: string;
};
