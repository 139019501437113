import React from 'react';

import CheckoutHeader from 'root/client/components/checkout-header/checkout-header';
import CheckoutPromoBanner from 'root/client/components/checkout-promo-banner/checkout-promo-banner';
import CheckoutSteps from 'root/client/components/checkout-steps/checkout-steps';
import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ContentContainer from 'root/client/components/content-container/content-container';
import DiscountCodeInput from 'root/client/components/discount-code-input/discount-code-input';
import ProductCards from 'root/client/components/product-cards/product-cards';
import Text from 'root/client/components/text/text';

import type { CheckoutSelectProductPage as Props } from './checkout-select-product-page.types';

const CheckoutSelectProductPage: React.FunctionComponent<Props> = ({
  checkoutSteps,
  checkoutPromoBanner,
  disclaimer,
  discountCode,
  layout,
  title,
  products,
}) => (
  <CleanLayout {...layout}>
    <div className="checkout-select-product-page">
      <ContentContainer>
        <CheckoutHeader title={title} />
        <CheckoutSteps {...checkoutSteps} />
      </ContentContainer>

      {discountCode && checkoutPromoBanner ? (
        <ContentContainer>
          <CheckoutPromoBanner {...checkoutPromoBanner} />
        </ContentContainer>
      ) : null}

      <ContentContainer>
        <ProductCards {...products} />
        <div className="checkout-select-product-page__disclaimer">
          <Text size={Text.sizes.small}>{disclaimer}</Text>
        </div>
      </ContentContainer>
      {discountCode ? (
        <ContentContainer width={ContentContainer.widths.Slim}>
          <DiscountCodeInput {...discountCode} {...checkoutPromoBanner} />
        </ContentContainer>
      ) : null}
    </div>
  </CleanLayout>
);

export default CheckoutSelectProductPage;
