import React from 'react';

import LinkButton from '../link-button/link-button';
import Message from '../message/message';
import Text from '../text/text';

import type { MessageWithLink as Props } from './message-with-link.types';

const MessageWithLink: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ link, text }) => (
  <Message type={Message.types.error}>
    <div className="message-with-link__text">
      <Text>{text}</Text>
    </div>
    <LinkButton size={LinkButton.sizes.small} {...link} />
  </Message>
);

export default MessageWithLink;
