import React, { useMemo, useState } from 'react';

import AlternateCheckoutMembershipContent from '../../components/alternate-checkout-membership-content/alternate-checkout-membership-content';
import LinkButton from '../../components/link-button/link-button';
import SalesLayout from '../../components/sales-layout/sales-layout';
import Spinner from '../../components/spinner/spinner';
import apiHelper from '../../ts/api-helper';

import type { AlternateCheckoutMembership as Props } from './alternate-checkout-membership.types';

const AlternateCheckoutMembership: React.FC<Props> = ({
  actions,
  content,
  errorMessages,
  layout,
}) => {
  const [currentContent, setCurrentContent] = useState(content);
  const [dynamicMessages, setDynamicMessages] = useState<
    Parameters<typeof SalesLayout>[0]['dynamicMessages']
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);

  const showSpinner = useMemo(
    () => isLoading || isNavigating,
    [isLoading, isNavigating],
  );

  const handleInteraction = (hrefs: { data: string; gui: string }) => {
    if (isLoading) {
      return;
    }

    setDynamicMessages([]);
    setIsLoading(true);
    apiHelper
      .get(hrefs.data)
      .then(setCurrentContent)
      .then(() => window.history.replaceState(null, document.title, hrefs.gui))
      .catch(
        apiHelper.overrideStatus({
          400: () => {
            setDynamicMessages([errorMessages[400]]);
            return true;
          },
          404: () => {
            setDynamicMessages([errorMessages[404]]);
            return true;
          },
        }),
      )
      .catch(() => {
        setDynamicMessages([errorMessages.generic]);
      })
      .finally(() => setIsLoading(false));
  };

  const handleNavigation: NonNullable<
    Parameters<typeof LinkButton>[0]['onClick']
  > = event => {
    if (isNavigating) {
      event.preventDefault();
    } else {
      setIsNavigating(true);
    }
  };

  return (
    <SalesLayout {...layout} dynamicMessages={dynamicMessages}>
      <div className="alternate-checkout-membership">
        {showSpinner ? (
          <div className="alternate-checkout-membership__loader">
            <div>
              <Spinner />
            </div>
          </div>
        ) : null}
        <AlternateCheckoutMembershipContent
          {...currentContent}
          onInteraction={handleInteraction}
        />
        <div className="alternate-checkout-membership__actions">
          {actions.map(entry => (
            <div key={entry.href}>
              <LinkButton {...entry} onClick={handleNavigation} wide />
            </div>
          ))}
        </div>
      </div>
    </SalesLayout>
  );
};

export default AlternateCheckoutMembership;
