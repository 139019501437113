import cn from 'classnames';
import React from 'react';

import type { Text as Props } from './text.types';
import { sizes, themes, variants } from './text.types';

const Text: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  sizes: typeof sizes;
  themes: typeof themes;
  variants: typeof variants;
} = ({
  children,
  className,
  elementName = 'span',
  size = sizes.basic,
  theme = themes.normal,
  tight,
  variant = variants.content,
}) =>
  React.createElement(
    elementName,
    {
      className: cn('text', {
        [`text--size-${size}`]: size,
        [`text--theme-${theme}`]: theme,
        'text--tight': tight,
        [`text--variant-${variant}`]: variant,
        [`${className}`]: className,
      }),
    },
    children,
  );

Text.sizes = sizes;
Text.themes = themes;
Text.variants = variants;

export default Text;
