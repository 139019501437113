import { fromQueryString } from 'root/shared/from-query-string';

import { publish } from './messages';
import { trackEvents } from './tracking';

const defaultFetchOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Result = { json: any; response: Response };

class ApiError extends Error {
  responseStatus: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  responseBody: any;
  extra: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: any;
  };
  constructor(message: string) {
    super(message);
  }
}

const parseResponse = (response: Response): Promise<Result> =>
  response
    .text()
    .then(text => (text ? JSON.parse(text) : {}))
    .catch(() => {})
    .then(json => ({ json: json || {}, response }));

const publishMessage = ({ json, response }: Result) => {
  if (json.userMessage) {
    publish(json.userMessage);
  }

  return { json, response };
};

const handleNotOk = ({ json, response }: Result) => {
  // NOTE: `response.ok` is true when the returned status is in the inclusive range 200-299.
  if (!response.ok) {
    const error = new ApiError(response.statusText);

    const [url, query] = response.url.split('?');

    error.name = `${response.status} on ${url}`;
    error.responseStatus = response.status;
    error.responseBody = json;
    error.extra = {
      query: query ? fromQueryString(query) : undefined,
      response: json,
    };

    throw error;
  }

  return json;
};

/** Use this in a `catch` before a generic `catch`.

If a specified status' handler returns a truthy value,
the rejection is not propagated further.
If a specified status' handler returns a falsy value,
the rejection is propagated, so the callee can handle any
generic errors by cleaning up UI, displaying a message,
cancelling timers etc.

```js
post("some/url")
      .then(resultHandler)
      .catch(overrideStatus({
        401: () => {
          showErrorMessage("Please log in");
          return true;
        }
      }))
      .catch(genericErrorHandler)
      .finally(finallyHandler)
```
*/
const overrideStatus =
  (
    overrides: Record<
      number,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (body: any) => boolean | void
    > = {},
  ) =>
  (error: ApiError) => {
    if (overrides[error.responseStatus]?.(error.responseBody || {})) {
      return;
    }

    throw error;
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleAnalytics = (data: any) => {
  if (data?.dataLayer) {
    trackEvents(data.dataLayer);
  }
  return data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unpackPayload = (r: any) => (r ? r.payload || r : r);

const request = (url: string, options: RequestInit = {}) => {
  const fetchOptions = {
    ...defaultFetchOptions,
    ...options,
    headers: {
      ...defaultFetchOptions.headers,
      ...options.headers,
    },
  };
  return fetch(url, fetchOptions)
    .catch(error => {
      throw error;
    })
    .then(parseResponse)
    .then(publishMessage)
    .then(handleNotOk)
    .then(handleAnalytics)
    .then(unpackPayload);
};

// NOTE: 'delete' is a reserved word
const Delete = (endpoint: string, options: RequestInit = {}) =>
  request(endpoint, { ...options, method: 'delete' });

const get = (endpoint: string, options?: RequestInit) =>
  request(endpoint, options);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const post = (endpoint: string, data: any, options?: RequestInit) =>
  request(
    endpoint,
    Object.assign({}, options, {
      body: JSON.stringify(data),
      method: 'post',
    }),
  );

export default {
  defaultFetchOptions,
  delete: Delete,
  get,
  overrideStatus,
  post,
};
