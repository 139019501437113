import React from 'react';

import Button from '../button/button';
import { useSalesforceChat } from '../../hooks/use-salesforce-chat';
import useToggle from '../../hooks/use-toggle';

import type { MemberChatTrigger as Props } from './member-chat-trigger.types';

const MemberChatTrigger: React.FunctionComponent<Props> = ({
  esw,
  init,
  label,
  settings,
}) => {
  const [isTriggerEnabled, , enableTrigger] = useToggle(false);

  useSalesforceChat(esw, init, settings, enableTrigger);

  const triggerChat = () => {
    if (!window.embedded_svc) {
      // NOTE: Show message?
      return;
    }

    if (typeof window.embedded_svc.onHelpButtonClick !== 'function') {
      // NOTE: Show message?
      return;
    }

    window.embedded_svc.onHelpButtonClick();
  };

  return (
    <div className="member-chat-trigger">
      <Button disabled={!isTriggerEnabled} onClick={triggerChat} text={label} />
    </div>
  );
};

export default MemberChatTrigger;
