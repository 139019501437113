export const fromQueryString = (
  queryString: string,
): Record<string, string | string[]> => {
  if (!queryString || queryString === '?') {
    return {};
  }

  let url;
  try {
    const usableQueryString = queryString.startsWith('?')
      ? queryString
      : `?${queryString}`;
    url = new URL(usableQueryString, 'https://example.com/');
  } catch {
    return {};
  }

  const bucket: ReturnType<typeof fromQueryString> = {};
  url.searchParams.forEach((value, name) => {
    let tempValue: string | string[] = value;
    if (value.includes(',')) {
      tempValue = value.split(',').filter(id => id);
    }

    const currentValue: string | string[] | undefined = bucket[name];
    if (Array.isArray(currentValue)) {
      if (Array.isArray(tempValue)) {
        bucket[name] = currentValue.concat(...tempValue);
      } else {
        bucket[name] = currentValue.concat(tempValue);
      }
    } else if (typeof currentValue === 'string') {
      if (Array.isArray(tempValue)) {
        bucket[name] = [currentValue].concat(...tempValue);
      } else {
        bucket[name] = [currentValue, tempValue];
      }
    } else if (tempValue) {
      bucket[name] = tempValue;
    }
  });

  return bucket;
};
