import type { Block, Inline } from '@contentful/rich-text-types';

import type { CTABanner } from '../cta-banner/cta-banner.types';
import type { EmbedYoutube } from '../embed-youtube/embed-youtube.types';
import type { Table } from '../table/table.types';
import { themes as buttonThemes } from '../visually-button/visually-button.types';

export const toCtaBannerProps = (
  node: Block | Inline,
): CTABanner | undefined => {
  const { heading, description, ctaUrl, ctaText, primary } = node?.data?.target
    ?.fields
    ? node.data.target.fields
    : {
        heading: undefined,
        description: undefined,
        ctaUrl: undefined,
        ctaText: undefined,
        primary: undefined,
      };

  const link =
    ctaUrl && ctaText
      ? {
          href: ctaUrl,
          text: ctaText,
          theme: primary ? buttonThemes.cta : buttonThemes.secondary,
        }
      : undefined;

  if (heading && description && link) {
    return { title: heading, text: description, link };
  }

  return undefined;
};

export const toEmbedYoutubeProps = (
  node: Block | Inline,
): EmbedYoutube | undefined => {
  const id = node?.data?.target?.fields?.embedCode;

  if (id) {
    return { id };
  }

  return undefined;
};

export const toTableProps = (node: Block | Inline): Table | undefined => {
  const rows = node?.data?.target?.fields?.rows;

  if (rows) {
    return { rows };
  }

  return undefined;
};
