import cn from 'classnames';
import Discount32 from '@sats-group/icons/32/discount';
import React, { useEffect, useRef } from 'react';
import StarSticker32 from '@sats-group/icons/32/star-sticker';

import Collapse from '../collapse/collapse';
import LayeredIcon from '../layered-icon/layered-icon';
import SellingPoints from '../selling-points/selling-points';
import Text from '../text/text';

import type { ProductOptionCard as Props } from './product-option-card.types';

const ProductOptionCard: React.FunctionComponent<Props> = ({
  description,
  disclaimer,
  expanded,
  intersectionRoot,
  link,
  name,
  onIntersection,
  price,
  sellingPoints,
  tag,
}) => {
  const card = useRef();

  const handleIntersection: IntersectionObserverCallback = entries =>
    onIntersection
      ? onIntersection(
          entries.some(
            entry => entry.isIntersecting && entry.intersectionRatio === 1,
          ),
        )
      : undefined;

  useEffect(() => {
    if (!card.current) {
      return;
    }

    const observer = new IntersectionObserver(handleIntersection, {
      root: intersectionRoot ? intersectionRoot.current : undefined,
      threshold: 1,
    });

    observer.observe(card.current);

    return () => observer.disconnect();
  }, []);

  return React.createElement(
    link ? 'a' : 'div',
    {
      className: cn('product-option-card', {
        'product-option-card--expanded': expanded,
        'product-option-card--link': link,
      }),
      href: link ? link.href : undefined,
      ref: card,
    },
    <React.Fragment>
      <div className="product-option-card__primary">
        {tag ? (
          <div className="product-option-card__tag">
            <div className="product-option-card__tag-icon">
              <LayeredIcon
                icons={[
                  { icon: <StarSticker32 />, color: 'dark' },
                  { icon: <Discount32 />, color: 'light' },
                ]}
              />
            </div>
            <Text>{tag}</Text>
          </div>
        ) : null}
        <div className="product-option-card__main">
          <Text
            size={Text.sizes.header1}
            tight
            variant={Text.variants.contrast}
          >
            {name}
          </Text>
          <div className="product-option-card__price">
            <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
              {price.pre}
            </Text>
            <Text size={Text.sizes.header1} tight variant={Text.variants.hero}>
              {price.value}
            </Text>
            <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
              {price.post}
            </Text>
          </div>
        </div>
        <div className="product-option-card__description">
          <Text size={Text.sizes.small} theme={Text.themes.medium} tight>
            {description}
          </Text>
        </div>
      </div>
      <Collapse isOpen={expanded}>
        <div
          className={cn('product-option-card__secondary', {
            'product-option-card__secondary--expanded': expanded,
            'product-option-card__secondary--w-action': link,
          })}
        >
          <div className="product-option-card__selling-points">
            <SellingPoints {...sellingPoints} />
          </div>
          {disclaimer ? (
            <div className="product-option-card__disclaimer">
              <Text>{disclaimer}</Text>
            </div>
          ) : null}
          {link ? (
            <div className="product-option-card__action">
              <Text theme={Text.themes.medium} tight>
                {link.text}
              </Text>
            </div>
          ) : null}
        </div>
      </Collapse>
    </React.Fragment>,
  );
};

export default ProductOptionCard;
