import type { Document } from '@contentful/rich-text-types';

export enum Theme {
  Intro = 'intro',
}

export type ChildClassNames = {
  image?: string;
};

export type RichText = {
  className?: string;
  childClassNames?: ChildClassNames;
  content?: Document;
  theme?: Theme;
};
