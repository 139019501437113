import React from 'react';

import Button from '../button/button';
import ContentContainer from '../content-container/content-container';
import HiddenInput from '../hidden-input/hidden-input';
import LinkButton from '../link-button/link-button';

import type { CheckoutActions as Props } from './checkout-actions.types';

const CheckoutActions: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  children,
  hiddenInputs = [],
  method = 'get',
  nextStep,
  nextStepLabel,
  previousStep,
  previousStepLabel,
  width,
}) =>
  React.createElement(
    nextStep ? 'form' : 'div',
    {
      action: nextStep ? nextStep : undefined,
      className: 'checkout-actions',
      method: nextStep ? method : undefined,
    },
    <ContentContainer width={width}>
      {children}
      <div className="checkout-actions__actions">
        {nextStep ? <Button type="submit" text={nextStepLabel} /> : null}
        {previousStep ? (
          <LinkButton
            href={previousStep}
            theme={LinkButton.themes.secondary}
            text={previousStepLabel}
          />
        ) : null}
      </div>
      {nextStep
        ? hiddenInputs.map(input => <HiddenInput key={input.name} {...input} />)
        : null}
    </ContentContainer>,
  );

export default CheckoutActions;
