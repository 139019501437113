import cn from 'classnames';
import type { ImgHTMLAttributes } from 'react';
import React from 'react';

import { replaceQueryParameters } from 'root/shared/replace-query-parameters';
import useBoundingClientRect from 'root/client/hooks/use-bounding-client-rect';
import useDevicePixelRatio from 'root/client/hooks/use-device-pixel-ratio';

import type { Image as Props } from './image.types';
import { AspectRatio } from './image.types';

type RestProps = Omit<ImgHTMLAttributes<HTMLImageElement>, keyof Props>;

// NOTE: These are the formats Contentful Images API supports that we want to use
const supportedFormats = [
  ['webp', 'image/webp'],
  ['jpg', 'image/jpeg'],
];

// "The maximum allowed value is 4000 pixels.".
// Larger values result in error responses.
const maximumPixels = 4000;

/** Renders an image using Contentful Images API
 * https://www.contentful.com/developers/docs/references/images-api/
 */
const Image: React.FunctionComponent<RestProps & Props> & {
  aspectRatios: typeof AspectRatio;
} = ({ alt, aspectRatio, className, src, ...rest }) => {
  const dpr = useDevicePixelRatio();
  const [setElement, { height, width }] = useBoundingClientRect();

  // NOTE: These are all properties supported by Contentful Images API
  const dynamicSrc = replaceQueryParameters(src, {
    fit: 'fill',
    h: height ? Math.min(Math.ceil(height * dpr), maximumPixels) : undefined,
    w: width ? Math.min(Math.ceil(width * dpr), maximumPixels) : undefined,
    q: 75,
  });

  const hasRatio = aspectRatio
    ? Object.values(AspectRatio).includes(aspectRatio)
    : false;

  return (
    <picture
      className={cn(
        'image',
        {
          [`image--${aspectRatio}`]: hasRatio,
          'image--ratioed': hasRatio,
        },
        className,
      )}
      {...rest}
      ref={setElement}
    >
      {supportedFormats.map(([fm, type]) => (
        <source
          key={fm}
          media="all"
          srcSet={replaceQueryParameters(dynamicSrc, { fm })}
          type={type}
        />
      ))}
      <img alt={alt} src={dynamicSrc} {...rest} />
    </picture>
  );
};

Image.aspectRatios = AspectRatio;

export default Image;
