export enum Width {
  Slim = 'slim',
  Medium = 'medium',
  Narrow = 'narrow',
  Wide = 'wide',
  Full = 'full',
}

export type ContentContainer = {
  className?: string;
  width?: Width;
};
