export enum AspectRatio {
  Square = 'square',
  FourThree = '4-3',
  Fill = 'fill',
  ThreeTwo = '3-2',
  SixteenNine = '16-9',
}

export type Image = {
  alt: string;
  aspectRatio?: AspectRatio;
  src: string;
};
