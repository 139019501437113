import cn from 'classnames';
import React from 'react';

// NOTE: This file has more components below ColorBlockLayout
/* eslint-disable react/no-multi-comp */

enum Color {
  Primary = 'primary',
  Secondary = 'secondary',
}

enum Padding {
  None = 'none',
  Some = 'some',
  AllSides = 'allsides',
}

const ColorBlockLayout: React.FunctionComponent<React.PropsWithChildren> & {
  Block: typeof Block;
  colors: typeof Color;
  paddings: typeof Padding;
} = ({ children }) => <div className="color-block-layout">{children}</div>;

const Block: React.FunctionComponent<
  React.PropsWithChildren<{ color?: Color; padding?: Padding }>
> = ({ children, color = Color.Primary, padding = Padding.Some }) => (
  <div
    className={cn('color-block-layout__block', {
      [`color-block-layout__block--${color}`]: color,
      [`color-block-layout__block--padding-${padding}`]: padding,
    })}
  >
    <div
      className={cn('color-block-layout__content', {
        [`color-block-layout__content--padding-${padding}`]: padding,
      })}
    >
      {children}
    </div>
  </div>
);

ColorBlockLayout.Block = Block;
ColorBlockLayout.colors = Color;
ColorBlockLayout.paddings = Padding;

export default ColorBlockLayout;
