import { useEffect, useState } from 'react';

export default function useBoundingClientRect() {
  const [element, setElement] = useState<HTMLElement | null>();
  const [rect, setRect] = useState<Partial<DOMRect>>({
    width: 185,
  });

  useEffect(() => {
    if (element) {
      setRect(element.getBoundingClientRect());
    }
  }, [element]);

  return [setElement, rect] as [
    setElement: typeof setElement,
    rect: typeof rect,
  ];
}
