import React from 'react';

import ArticleList from 'root/client/components/article-list/article-list';
import Container from 'root/client/components/container/container';
import ContentContainer from 'root/client/components/content-container/content-container';
import Hero from 'root/client/components/hero/hero';
import Layout from 'root/client/components/layout/layout';
import LinkCards from 'root/client/components/link-cards/link-cards';
import ProfileCardList from 'root/client/components/profile-card-list/profile-card-list';
import SplitInfoModule from 'root/client/components/split-info-module/split-info-module';
import Text from 'root/client/components/text/text';

import type { PersonalTrainingPage as Props } from './personal-training-page.types';

const PersonalTrainingPage: React.FunctionComponent<Props> = ({
  findPt,
  hero,
  info,
  inspiration,
  layout,
  related,
}) => (
  <Layout {...layout}>
    <div className="personal-training-page">
      <Hero {...hero} short />
      <div className="personal-training-page__content">
        <div>
          <ContentContainer>
            <SplitInfoModule {...info} flipped />
          </ContentContainer>
        </div>
        <div>
          <ContentContainer>
            <ProfileCardList {...findPt} slider />
          </ContentContainer>
        </div>
        {inspiration ? (
          <div className="personal-training-page__inspiration">
            <Container size="s">
              <div className="personal-training-page__inspiration-title">
                <Text size={Text.sizes.header3} variant={Text.variants.hero}>
                  {inspiration.header}
                </Text>
              </div>
              <div className="personal-training-page__inspiration-description">
                <Text size={Text.sizes.large}>{inspiration.introduction}</Text>
              </div>
            </Container>
            <ContentContainer>
              <ArticleList {...inspiration.articles} />
            </ContentContainer>
          </div>
        ) : null}
        <div>
          <ContentContainer>
            <LinkCards {...related} />
          </ContentContainer>
        </div>
      </div>
    </div>
  </Layout>
);

export default PersonalTrainingPage;
