import React from 'react';

import Layout from 'root/client/components/layout/layout';
import Link from 'root/client/components/link/link';
import Text from 'root/client/components/text/text';

import type { EmployeeLogInPage as Props } from './employee-log-in-page.types';

const EmployeeLogInPage: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ layout, link, title }) => (
  <Layout {...layout}>
    <div className="employee-log-in-page">
      <div className="employee-log-in-page__title">
        <Text
          elementName="h1"
          size={Text.sizes.header2}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>
      <Link {...link} />
    </div>
  </Layout>
);

export default EmployeeLogInPage;
