import Loading from '@sats-group/icons/36/loading';
import React from 'react';

const Spinner: React.FunctionComponent = () => (
  <div className="spinner">
    <div className="spinner__icon">
      <Loading />
    </div>
  </div>
);

export default Spinner;
