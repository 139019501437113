export const trackEvent = (data: unknown) => {
  window.dataLayer = window.dataLayer || [];
  if (Array.isArray(window.dataLayer)) {
    // @ts-expect-error
    window.dataLayer.push(data);
  }
};

export const trackEvents = (events: unknown) => {
  if (Array.isArray(events)) {
    events.forEach(trackEvent);
  }
};
