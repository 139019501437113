import React from 'react';

import ContentBlocks from '../../components/content-blocks/content-blocks';
import ContentContainer from '../../components/content-container/content-container';
import Hero from '../../components/hero/hero';
import Layout from '../../components/layout/layout';
import Text from '../../components/text/text';

import type { LandingPage as Props } from './landing-page.types';

const LandingPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  blocks,
  hero,
  layout,
  title,
}) => (
  <Layout {...layout}>
    <div className="landing-page">
      {hero ? <Hero {...hero} /> : null}
      <ContentContainer>
        {!hero ? (
          <div className="landing-page__title">
            <Text
              elementName="h1"
              size={Text.sizes.header2}
              variant={Text.variants.hero}
            >
              {title}
            </Text>
          </div>
        ) : null}
        <section className="landing-page__blocks">
          <ContentBlocks {...blocks} />
        </section>
      </ContentContainer>
    </div>
  </Layout>
);

export default LandingPage;
