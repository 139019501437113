import ArrowRight18 from '@sats-group/icons/18/arrow-right';
import React from 'react';

import Text from '../text/text';

import type { Breadcrumbs as Props } from './breadcrumbs.types';

const Breadcrumbs: React.FunctionComponent<Props> = ({ current, previous }) => (
  <div className="breadcrumbs">
    {previous.map(entry => (
      <React.Fragment key={entry.href}>
        <div className="breadcrumbs__item">
          <a className="breadcrumbs__link" href={entry.href}>
            <Text size={Text.sizes.small}>{entry.text}</Text>
          </a>
        </div>
        <div className="breadcrumbs__item">
          <ArrowRight18 />
        </div>
      </React.Fragment>
    ))}
    <div aria-current="page" className="breadcrumbs__item">
      <Text size={Text.sizes.small}>{current}</Text>
    </div>
  </div>
);

export default Breadcrumbs;
