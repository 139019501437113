import { fromQueryString } from './from-query-string';
import { toQueryString } from './to-query-string';

export function replaceQueryParameters(
  url: string,
  query?: Parameters<typeof toQueryString>[0],
) {
  if (!url || !query) {
    return url;
  }

  const [baseUrl, queryString] = url.split(/\?|#/);
  const newQuery = queryString
    ? Object.assign({}, fromQueryString(queryString), query)
    : query;

  const newQueryString = toQueryString(newQuery);

  return `${baseUrl ? baseUrl : ''}${
    newQueryString.endsWith('?')
      ? newQueryString.slice(0, newQueryString.length - 1)
      : newQueryString
  }`;
}
