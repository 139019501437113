import type { NodeRenderer } from '@contentful/rich-text-react-renderer';
import React from 'react';

const TableRenderer: NodeRenderer = (_node, children) => (
  <div className="rich-text__native-table">
    {/* NOTE: This is Contentful's default renderer, but it's not exported so we copied it */}
    {React.createElement(
      'table',
      null,
      React.createElement('tbody', null, children),
    )}
  </div>
);

export default TableRenderer;
