import Check16 from '@sats-group/icons/16/check';
import cn from 'classnames';
import React from 'react';
import Remove16 from '@sats-group/icons/16/remove';

import type { SellingPoint as Props } from './selling-point.types';

const SellingPoint: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  isIncluded,
  text,
}) => (
  <div
    aria-hidden={!isIncluded}
    className={cn('selling-point', {
      'selling-point--included': isIncluded,
    })}
  >
    <div className="selling-point__icon">
      {isIncluded ? <Check16 /> : <Remove16 />}
    </div>
    <div className="selling-point__text">{text}</div>
  </div>
);

export default SellingPoint;
