import Discount32 from '@sats-group/icons/32/discount';
import React from 'react';
import StarSticker32 from '@sats-group/icons/32/star-sticker';

import {
  type LayeredIcon as LayeredIconType,
  colors,
} from 'root/client/components/layered-icon/layered-icon.types';
import LayeredIcon from 'root/client/components/layered-icon/layered-icon';
import Text from 'root/client/components/text/text';

import type { CheckoutPromoBanner as Props } from './checkout-promo-banner.types';

const CheckoutPromoBanner: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ campaignLabel, campaignExtraInformation }) => {
  const icons: LayeredIconType['icons'] = [
    { icon: <StarSticker32 />, color: colors.dark },
    { icon: <Discount32 />, color: colors.light },
  ];

  return (
    <div className="checkout-promo-banner">
      <div className="checkout-promo-banner__icon">
        <LayeredIcon icons={icons} />
      </div>

      <div>
        {campaignLabel ? (
          <div className="checkout-promo-banner__title">
            <Text>{campaignLabel}</Text>
          </div>
        ) : undefined}

        {campaignExtraInformation ? (
          <div className="checkout-promo-banner__sub-title">
            <Text elementName="p">{campaignExtraInformation}</Text>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default CheckoutPromoBanner;
