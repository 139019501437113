import React, { useEffect, useRef } from 'react';

import { useMapbox } from 'root/client/hooks/use-mapbox';

import type { Map as Props } from './map.types';

const Map: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  accessToken,
  locations,
  options,
}) => {
  const { mapbox } = useMapbox();
  const mapNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapbox) {
      return;
    }

    mapbox.accessToken = accessToken;

    if (!mapNode.current) {
      return;
    }

    const map = new mapbox.Map({
      container: mapNode.current,
      ...options,
    });

    locations.forEach(({ lat, lng, popup }) => {
      const marker = new mapbox.Marker().setLngLat([lng, lat]);

      if (popup) {
        marker.setPopup(new mapbox.Popup().setHTML(popup));
      }

      marker.addTo(map);
    });
  }, [mapbox]);

  return <div className="map" ref={mapNode}></div>;
};

export default Map;
