import cn from 'classnames';
import React from 'react';

import RichText from '../rich-text/rich-text';
import Text from '../text/text';

import type { TextBlock as Props } from './text-block.types';

const TextBlock: React.FunctionComponent<Props> = ({
  body,
  tall,
  textAlignment = 'center',
  title,
}) => (
  <div
    className={cn('text-block', {
      'text-block--tall': tall,
      [`text-block--${textAlignment}`]: textAlignment,
    })}
  >
    {title ? (
      <Text
        elementName="h2"
        size={Text.sizes.header2}
        theme={Text.themes.emphasis}
      >
        {title}
      </Text>
    ) : null}
    <RichText {...body} />
  </div>
);

export default TextBlock;
