import Forward18 from '@sats-group/icons/18/forward';
import React from 'react';

import Text from '../text/text';
import VisuallyButton from '../visually-button/visually-button';

import type { ProfileLinkCard as Props } from './profile-link-card.types';

const ProfileLinkCard: React.FunctionComponent<Props> = ({ href, text }) => (
  <a className="profile-link-card" href={href}>
    <div className="profile-link-card__text">
      <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
        {text}
      </Text>
    </div>
    <div className="profile-link-card__icon">
      <VisuallyButton
        elementName="div"
        icon={<Forward18 />}
        iconOnly
        text={text}
        theme={VisuallyButton.themes.secondaryWhite}
      />
    </div>
  </a>
);

export default ProfileLinkCard;
