import React from 'react';

import ContentContainer from 'root/client/components/content-container/content-container';
import ExpandableClubs from 'root/client/components/expandable-clubs/expandable-clubs';
import Layout from 'root/client/components/layout/layout';
import Link from 'root/client/components/link/link';
import Text from 'root/client/components/text/text';

import type { ClubListPage as Props } from './club-list-page.types';

const ClubListPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  introduction,
  layout,
  clusters,
  map,
  title,
}) => (
  <Layout {...layout} theme={Layout.themes.Primary}>
    <ContentContainer
      className="club-list-page"
      width={ContentContainer.widths.Narrow}
    >
      <div className="club-list-page__title">
        <Text
          elementName="h1"
          size={Text.sizes.header2}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>
      <div className="club-list-page__introduction">
        <Text size={Text.sizes.basic} elementName="p">
          {introduction}
        </Text>
      </div>
      {map ? (
        <div className="club-list-page__link">
          <Link {...map} />
        </div>
      ) : null}
      <ExpandableClubs {...clusters} />
    </ContentContainer>
  </Layout>
);

export default ClubListPage;
