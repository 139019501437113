import ArrowDown24 from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import Forward18 from '@sats-group/icons/18/forward';
import React, { useRef, useState } from 'react';

import useIsMounted from 'root/client/hooks/use-is-mounted';
import useToggle from 'root/client/hooks/use-toggle';

import Container from '../container/container';
import LinkButton from '../link-button/link-button';
import ProductOptionCard from '../product-option-card/product-option-card';
import Text from '../text/text';

import type { ProductOptionCards as Props } from './product-option-cards.types';

const ProductOptionCards: React.FunctionComponent<Props> = ({
  actions,
  cards,
  contract,
  description,
  expand,
  expanded,
  title,
}) => {
  const isMounted = useIsMounted();
  const cardSection = useRef(null);
  const [visibleCards, setVisibleCards] = useState<Record<string, boolean>>({});
  const [isExpanded, toggleExpanded] = useToggle(Boolean(expanded));

  const handleIntersection = (id: string) => (isInView: boolean) =>
    setVisibleCards(value => {
      if (value[id] === isInView) {
        return value;
      }

      return Object.assign({}, value, { [id]: isInView });
    });

  return (
    <div className="product-option-cards">
      <div className="product-option-cards__inner">
        {title || description ? (
          <Container size="s">
            {title ? (
              <div className="product-option-cards__title">
                <Text size={Text.sizes.header3} variant={Text.variants.hero}>
                  {title}
                </Text>
              </div>
            ) : null}
            {description ? (
              <div className="product-option-cards__description">
                <Text size={Text.sizes.large}>{description}</Text>
              </div>
            ) : null}
          </Container>
        ) : null}
        <Container size="m">
          <div
            className="product-option-cards__cards-section"
            ref={cardSection}
          >
            <div className="product-option-cards__cards">
              <div className="product-option-cards__card-track">
                <div className="product-option-cards__card product-option-cards__card--fake" />
                {cards.map(card => (
                  <div
                    className={cn('product-option-cards__card', {
                      'product-option-cards__card--muted':
                        !visibleCards[card.id],
                    })}
                    key={card.id}
                  >
                    <ProductOptionCard
                      {...card}
                      expanded={isExpanded}
                      intersectionRoot={cardSection}
                      onIntersection={handleIntersection(card.id)}
                    />
                  </div>
                ))}
                <div className="product-option-cards__card product-option-cards__card--fake" />
              </div>
            </div>
            {expanded ? null : (
              <button
                className={cn('product-option-cards__trigger', {
                  'product-option-cards__trigger--active': isExpanded,
                })}
                onClick={toggleExpanded}
              >
                <Text>{isExpanded ? contract : expand}</Text>
                <div className="product-option-cards__trigger-flourish">
                  <ArrowDown24 />
                </div>
              </button>
            )}
            {isMounted ? (
              <div className="product-option-cards__indicators">
                {cards.map(card => (
                  <div
                    className={cn('product-option-cards__indicator', {
                      'product-option-cards__indicator--active':
                        visibleCards[card.id],
                    })}
                    data-id={card.id}
                    key={card.id}
                  />
                ))}
              </div>
            ) : null}
          </div>
          {actions.length ? (
            <div className="product-option-cards__actions">
              {actions.map((action, index) => (
                <LinkButton
                  {...action}
                  icon={index ? <Forward18 /> : undefined}
                  iconPosition="right"
                  theme={
                    index
                      ? LinkButton.themes.secondary
                      : LinkButton.themes.complete
                  }
                  key={action.href}
                />
              ))}
            </div>
          ) : null}
        </Container>
      </div>
    </div>
  );
};

export default ProductOptionCards;
