import React from 'react';

import Tag from '../tag/tag';

import type { Tags as Props } from './tags.types';

const Tags: React.FunctionComponent<Props> = ({ tags, theme }) => (
  <div className="tags">
    {tags.map(tag => (
      <div className="tags__item" key={tag.text}>
        <Tag theme={theme} {...tag} />
      </div>
    ))}
  </div>
);

export default Tags;
