import React from 'react';

import Text from 'root/client/components/text/text';

import type { ConfirmationSummary as Props } from './confirmation-summary.types';
import Banner from '../banner/banner';

const ConfirmationSummary: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ banner, cells = [], lines = [] }) => (
  <div className="confirmation-summary">
    {banner ? <Banner {...banner} theme={Banner.themes.warning} /> : null}
    {cells.length ? (
      <dl className="confirmation-summary__cells">
        {cells.map(({ label, value }) => (
          <div className="confirmation-summary__cell" key={`${label}-${value}`}>
            <dt>
              <Text size={Text.sizes.small}>{label}</Text>
            </dt>
            <dd>
              <Text size={Text.sizes.basic}>{value}</Text>
            </dd>
          </div>
        ))}
      </dl>
    ) : null}
    {lines.length ? (
      <dl className="confirmation-summary__lines">
        {lines.map(({ isImportant, label, value }) => (
          <div
            className={'confirmation-summary__line'}
            key={`${label}-${value}`}
          >
            <dt>
              <Text size={isImportant ? Text.sizes.basic : Text.sizes.small}>
                {label}
              </Text>
            </dt>
            <dd>
              <Text size={isImportant ? Text.sizes.basic : Text.sizes.small}>
                {value}
              </Text>
            </dd>
          </div>
        ))}
      </dl>
    ) : null}
  </div>
);

export default ConfirmationSummary;
