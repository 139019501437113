import cn from 'classnames';
import React from 'react';

import type { LayeredIcon as Props } from './layered-icon.types';

const LayeredIcon: React.FunctionComponent<Props> = ({ icons }) => (
  <div className="layered-icon">
    {icons.map(({ color, icon }, index) => (
      <div
        className={cn('layered-icon__icon', `layered-icon__icon--${color}`)}
        key={index}
      >
        {icon}
      </div>
    ))}
  </div>
);

export default LayeredIcon;
