import React from 'react';

import Banner from '../banner/banner';
import Choicebox from '../choicebox/choicebox';
import DiscountSection from '../discount-section/discount-section';
import Message from '../message/message';
import Price from '../price/price';
import Text from '../text/text';

import type { AlternateCheckoutMembershipContent as Props } from './alternate-checkout-membership-content.types';

const AlternateCheckoutMembershipContent: React.FunctionComponent<Props> = ({
  discount,
  memberships,
  messages,
  onInteraction,
  payments,
  priceSummary,
}) => {
  const handleChoiceBoxClick =
    (hrefs: { data: string; gui: string }) => (event: MouseEvent) => {
      if (onInteraction) {
        event.preventDefault();
        onInteraction(hrefs);
      }
    };

  const handleDiscountInteraction = (hrefs: { data: string; gui: string }) => {
    if (onInteraction) {
      onInteraction(hrefs);
    }
  };

  return (
    <div className="alternate-checkout-membership-content">
      {messages.length ? (
        <div className="alternate-checkout-membership-content__group">
          {messages.map(entry => (
            <div key={entry.text}>
              <Message {...entry} />
            </div>
          ))}
        </div>
      ) : null}
      <div className="alternate-checkout-membership-content__group">
        <Text
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
        >
          {memberships.title}
        </Text>
        <div className="alternate-checkout-membership-content__options">
          {memberships.options.map(entry => (
            <div key={entry.id}>
              <Choicebox
                {...entry}
                onClick={handleChoiceBoxClick({
                  data: entry.api,
                  gui: entry.href,
                })}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="alternate-checkout-membership-content__group">
        <Text
          size={Text.sizes.large}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
        >
          {payments.title}
        </Text>
        <Banner {...payments.banner} theme={Banner.themes.information} />
        <div className="alternate-checkout-membership-content__options">
          {payments.options.map(entry => (
            <div key={entry.id}>
              <Choicebox
                {...entry}
                onClick={handleChoiceBoxClick({
                  data: entry.api,
                  gui: entry.href,
                })}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="alternate-checkout-membership-content__discount">
        <DiscountSection
          {...discount}
          onInteraction={handleDiscountInteraction}
        />
      </div>
      {priceSummary.price ? (
        <div className="alternate-checkout-membership-content__price">
          <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
            {priceSummary.title}
          </Text>
          <Price {...priceSummary.price} />
        </div>
      ) : null}
    </div>
  );
};

export default AlternateCheckoutMembershipContent;
