import { useEffect } from 'react';

import type { EmbeddedService } from '../types/salesforce-embedded-service';

type Init = Parameters<EmbeddedService['init']>;
type Settings = Partial<EmbeddedService['settings']>;

const initESW = (init: Init, settings: Settings, postInit: () => void) => {
  if (!window.embedded_svc) {
    return;
  }

  Object.assign(window.embedded_svc.settings, settings);
  window.embedded_svc.init(...init);

  postInit();
};

export const useSalesforceChat = (
  src: string,
  init: Init,
  settings: Settings,
  postInit: () => void,
) =>
  useEffect(() => {
    if (window.embedded_svc) {
      initESW(init, settings, postInit);
    } else {
      const script = document.createElement('script');
      script.onload = () =>
        initESW(
          // NOTE: Sets `gslbBaseURL` to `null`, as it's explicitly null
          // in the examples when loading esw for the first time.
          init.map((entry, index) => (index === 2 ? null : entry)) as Init,
          settings,
          postInit,
        );
      script.setAttribute('src', src);
      document.body.appendChild(script);
    }
  }, []);
