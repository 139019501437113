import React from 'react';

import Text from '../text/text';

import type { ProductCard as Props } from './product-card.types';

const ProductCard: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  chooseLabel,
  description = '',
  href,
  id,
  isDefault,
  name,
  price,
}) => (
  <a
    href={href}
    className="product-card"
    data-id={id}
    data-is-default={isDefault}
  >
    <div className="product-card__name">
      <Text size={Text.sizes.header2}>{name}</Text>
    </div>
    <div className="product-card__description">
      <Text size={Text.sizes.small}>{description}</Text>
    </div>
    <div className="product-card__price">
      <Text size={Text.sizes.large}>{price}</Text>
    </div>
    <div className="product-card__label">
      <Text elementName="div" size={Text.sizes.basic}>
        {chooseLabel}
      </Text>
    </div>
  </a>
);

export default ProductCard;
