import React from 'react';

import ContentContainer from '../content-container/content-container';

import type { Band as Props } from './band.types';

const Band: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  contentWidth,
  theme = 'information',
}) => (
  <div className={`band band--theme-${theme}`}>
    <ContentContainer width={contentWidth}>{children}</ContentContainer>
  </div>
);

export default Band;
