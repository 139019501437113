import React from 'react';

import Text from '../../components/text/text';

import type { InputButton as Props } from './input-button.types';

const InputButton = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<Props>
>(({ children, id, name, type = 'checkbox', required, ...props }, ref) => (
  <div className="input-button">
    <input
      {...props}
      id={id}
      name={name}
      type={type}
      required={required}
      ref={ref}
    />
    <div className="input-button__label">
      <label htmlFor={id}>
        <Text size={Text.sizes.small}>{children}</Text>
      </label>

      {required ? <span className="input-button__asterisk">*</span> : null}
    </div>
  </div>
));

export default InputButton;
