import React from 'react';

import ContentContainer from 'root/client/components/content-container/content-container';
import Layout from 'root/client/components/layout/layout';
import Link from 'root/client/components/link/link';
import Map from 'root/client/components/map/map';
import Text from 'root/client/components/text/text';

import type { ClubsMapPage as Props } from './clubs-map-page.types';

const ClubsMapPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  layout,
  list,
  map,
  title,
}) => (
  <Layout {...layout} theme={Layout.themes.Primary}>
    <ContentContainer className="clubs-map-page">
      <div className="clubs-map-page__title">
        <Text
          elementName="h1"
          size={Text.sizes.header2}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>
      <div className="clubs-map-page__link">
        <Link {...list} />
      </div>
      <Map {...map} />
    </ContentContainer>
  </Layout>
);

export default ClubsMapPage;
