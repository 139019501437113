import React from 'react';

import SellingPoint from './selling-point';
import type { SellingPoints as Props } from './selling-points.types';

const SellingPoints: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ points }) =>
  points ? (
    <ul className="selling-points">
      {points.map(point => (
        <li key={point.text}>
          <SellingPoint {...point} />
        </li>
      ))}
    </ul>
  ) : null;

export default SellingPoints;
