import { useEffect } from 'react';

import isRunningOnClient from '../ts/is-running-on-client';

const defaultTarget = isRunningOnClient ? window : null;

const useEvent = <T extends Event = Event>(
  name: string,
  handler: (e: T) => void,
  target: EventTarget | null = defaultTarget,
) => {
  useEffect(() => {
    if (!name) {
      return;
    }

    if (!target) {
      return;
    }

    target.addEventListener(name, handler);

    return () => {
      target.removeEventListener(name, handler);
    };
  }, [name, handler, target]);
};

export default useEvent;
