import React from 'react';

import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import ContentContainer from '../content-container/content-container';
import Text from '../text/text';

import type { PageHeader as Props } from './page-header.types';

const PageHeader: React.FunctionComponent<Props> = ({
  breadcrumbs,
  contentWidth,
  title,
}) => (
  <div className="page-header">
    <ContentContainer width={contentWidth}>
      <div className="page-header__content">
        {breadcrumbs ? <Breadcrumbs {...breadcrumbs} /> : null}
        <Text
          elementName="h1"
          size={Text.sizes.header2}
          tight
          variant={Text.variants.hero}
        >
          {title}
        </Text>
      </div>
    </ContentContainer>
  </div>
);

export default PageHeader;
