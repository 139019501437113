import { createElement } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { pageComponents } from './pages.generated';

const { component: componentName, props } = window.__INITIAL_DATA__ || {};

(() => {
  if (!componentName) {
    // eslint-disable-next-line no-console
    console.error('No component name found.');
    return;
  }

  const component = pageComponents[componentName];
  if (!component) {
    // eslint-disable-next-line no-console
    console.error(`Component "${componentName}" not found.`);
    return;
  }

  if (!props) {
    // eslint-disable-next-line no-console
    console.error('No props found.');
    return;
  }

  const mountPoint = document.getElementById('mount-point');
  if (!mountPoint) {
    // eslint-disable-next-line no-console
    console.error('No mount point found.');
    return;
  }

  hydrateRoot(mountPoint, createElement(component, props));
  document.documentElement.setAttribute('data-hydrated', '');
})();
