import type { InputHTMLAttributes } from 'react';
import React, { useRef } from 'react';

import type { RadioButton as Props } from './radio-button.types';

type RestProps = Omit<InputHTMLAttributes<HTMLInputElement>, keyof Props>;

const RadioButton: React.FunctionComponent<RestProps & Props> = ({
  defaultChecked,
  isRequired,
  label,
  name,
  value,
  visibleLabel = true,
  ...rest
}) => {
  const input: React.RefObject<HTMLInputElement> = useRef(null);

  return (
    <label className="radio">
      <input
        aria-label={label}
        name={name}
        required={isRequired}
        type="radio"
        value={value}
        defaultChecked={defaultChecked}
        ref={input}
        {...rest}
      />
      <div className="radio__label">
        <span className="radio__fake-element"></span>

        {visibleLabel ? <span className="radio__label">{label}</span> : null}
      </div>
    </label>
  );
};

export default RadioButton;
