import React, { useState } from 'react';
import Youtube24 from '@sats-group/icons/24/youtube';

import Button from 'root/client/components/button/button';
import Image from 'root/client/components/image/image';

import type { EmbedYoutube as Props } from './embed-youtube.types';

const EmbedYoutube: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  id,
  image,
  buttonText,
}) => {
  const hasPreviewProps = image && buttonText;
  const [playVideo, togglePlayVideo] = useState(!hasPreviewProps);

  return playVideo ? (
    <div className="embed-youtube">
      <div className="embed-youtube__player">
        <iframe
          src={`https://www.youtube.com/embed/${id}?autoplay=1`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  ) : hasPreviewProps ? (
    <div className="embed-youtube__preview">
      <Image {...image} />
      <div className="embed-youtube__play-button">
        <Button
          size={Button.sizes.small}
          icon={<Youtube24 />}
          onClick={() => togglePlayVideo(!playVideo)}
          text={buttonText}
        />
      </div>
    </div>
  ) : null;
};

export default EmbedYoutube;
