/** Adds protocol and hostname to a relative URL.
 * WARNING: This function references `window`
 */
export default (relativeUrl?: string) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  if (!relativeUrl) {
    return baseUrl;
  }

  const shouldAddSlash = !relativeUrl.startsWith('/');
  return [baseUrl, relativeUrl].join(shouldAddSlash ? '/' : '');
};
