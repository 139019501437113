import { useCallback } from 'react';

import useEvent from './use-event';

const useEscape = (callback: (event: KeyboardEvent) => void) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        callback(event);
      }
    },
    [callback],
  );

  useEvent<KeyboardEvent>('keydown', handleKeyDown);
};

export default useEscape;
