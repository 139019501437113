import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

import renderOptions from './render-options';
import type { RichText as Props } from './rich-text.types';
export { Theme } from './rich-text.types';

const RichText: React.FunctionComponent<Props> = ({
  className,
  childClassNames,
  content,
  theme,
}) => (
  <div className={['rich-text', className].join(' ')}>
    {content
      ? documentToReactComponents(
          content,
          renderOptions(childClassNames, theme),
        )
      : null}
  </div>
);

export default RichText;
