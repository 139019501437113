import ArrowDown from '@sats-group/icons/18/arrow-down';
import Booked from '@sats-group/icons/18/booked';
import React from 'react';

import useToggle from 'root/client/hooks/use-toggle';

import Collapse from '../collapse/collapse';
import Text from '../text/text';

import type { StepIndicator as Props } from './step-indicator.types';

const StepIndicator: React.FC<Props> = ({
  id,
  meta,
  progress,
  steps,
  title,
}) => {
  const [open, toggleOpen] = useToggle();

  return (
    <div className="step-indicator">
      <button
        aria-controls={id}
        className="step-indicator__trigger"
        onClick={toggleOpen}
      >
        <div className="step-indicator__text">
          <Text size={Text.sizes.large}>{title}</Text>
          {meta ? <Text className="step-indicator__meta">{meta}</Text> : null}
        </div>
        <div className="step-indicator__progress">
          <Text>{progress.label}</Text>
          <ArrowDown />
        </div>
      </button>
      <div aria-hidden className="step-indicator__progress-bar">
        <div
          className="step-indicator__progress-bar__percentage"
          style={{ width: progress.percentage }}
        />
      </div>
      <Collapse componentProps={{ id }} isOpen={open}>
        <nav className="step-indicator__steps">
          {steps.map((step, index, bucket) => (
            <React.Fragment key={step.id}>
              {React.createElement(
                step.href ? 'a' : 'span',
                {
                  'aria-current': step.state === 'current',
                  className: `step-indicator__step step-indicator__step--${step.state}`,
                  href: step.href,
                  key: step.id,
                },
                <React.Fragment>
                  {step.state === 'past' ? (
                    <div className="step-indicator__step-prefix step-indicator__step-prefix--completed">
                      <Booked />
                    </div>
                  ) : (
                    <div className="step-indicator__step-prefix">
                      <Text
                        theme={
                          step.state === 'current'
                            ? Text.themes.emphasis
                            : undefined
                        }
                      >
                        {step.index}
                      </Text>
                    </div>
                  )}
                  <Text
                    className="step-indicator__step-title"
                    theme={
                      step.state === 'past'
                        ? Text.themes.medium
                        : step.state === 'current'
                          ? Text.themes.emphasis
                          : undefined
                    }
                  >
                    {step.title}
                  </Text>
                  {step.subTitle ? (
                    <Text
                      className="step-indicator__step-sub"
                      size={Text.sizes.small}
                    >
                      {step.subTitle}
                    </Text>
                  ) : null}
                </React.Fragment>,
              )}
              {index < bucket.length - 1 ? (
                <hr aria-hidden className="step-indicator__separator" />
              ) : null}
            </React.Fragment>
          ))}
        </nav>
      </Collapse>
    </div>
  );
};

export default StepIndicator;
