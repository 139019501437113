import type { ValueOf } from 'type-fest';

import type { Image } from '../image/image.types';
import type { Tags } from '../tags/tags.types';

export const variants = {
  primary: 'primary',
  secondary: 'secondary',
} as const;

export type ArticleCard = {
  href: string;
  image: Image;
  overlay: string;
  tags?: Tags;
  tall?: boolean;
  text?: string;
  title: string;
  type?: 'adaptive' | 'small';
  variant?: ValueOf<typeof variants>;
};
