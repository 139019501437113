import React from 'react';

import ProductCard from '../product-card/product-card';

import type { ProductCards as Props } from './product-cards.types';

const ProductCards: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cards,
}) =>
  cards.length === 0 ? null : (
    <ul className="product-cards">
      {cards.map(card => (
        <li className="product-cards__item" key={card.id}>
          <ProductCard {...card} />
        </li>
      ))}
    </ul>
  );

export default ProductCards;
